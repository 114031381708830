<template>
  <app-card-item
    class="p-3 mb-3"
  >
    <app-heading class="mb-2">
      <div>
        {{ t('ttmt.support_requests.pages.show.sections.request') }} : {{ resource.attributes.title }}
      </div>
    </app-heading>

    <div class="mb-2">
      <!-- Category -->
      <div class="flex text-sm items-center mb-2">
        <app-icon
          class="mr-1"
          library="coolicon"
          name="tag-outline"
          size="text-base"
        />

        <span>
          {{ getAttributeTranslation(resource.relationships.category.attributes.title) }}
        </span>
      </div>

      <!-- Created at -->
      <div class="flex text-sm items-center justify-between">
        <div class="flex items-center">
          <app-icon
            class="mr-1"
            library="coolicon"
            name="clock"
            size="text-base"
          />

          <span>
            {{ formatDateShort(resource.attributes.created_at) }}
          </span>
        </div>

        <!-- State -->
        <app-status-badge
          :text="t(`ttmt.support_requests.states.${props.resource.attributes.state}`)"
          :design="stateDesign(props.resource.attributes.state)"
        />
      </div>

      <!-- First message -->
      <p class="mt-4 font-medium break-words">
        {{ firstMessage?.attributes.content }}
      </p>
    </div>
  </app-card-item>

  <!-- Attached files -->
  <app-card-item
    v-if="gallery.length > 0"
    class="p-4 my-3"
  >
    <p class="font-bold my-1">
      {{ t('validation.attributes.attached_files') }}
    </p>

    <images-gallery
      :images="gallery"
    />
  </app-card-item>

  <!-- Message-->
  <div class="flex flex-col pb-12">
    <app-card-item
      v-for="(message, i) in showedMessages"
      :key="i"
      :class="`
        p-4 my-2
        ${ userMessages?.includes(message) ? 'self-end w-4/5' : 'w-4/5'}
      `"
    >
      <support-message-card
        :resource="message"
        :first-message="firstMessage"
      />
    </app-card-item>
  </div>

  <div
    v-if="resource.authorizations.cancel"
    class="flex justify-center fixed right-0 left-0 bottom-36"
  >
    <app-button
      feature="cancel"
      class="w-5/6"
      :label="t('ttmt.common.actions.cancel')"
      @click="handleCancel"
    />
  </div>

  <div
    class="md:w-1/3"
  >
    <div
      v-if="canAddMessage"
      class="flex justify-center fixed right-0 left-0 bottom-24"
    >
      <app-button
        feature="create"
        class="w-5/6"
        :label="t('ttmt.common.actions.add_message')"
        @click="router.push({ name: 'create-support-messages' })"
      />
    </div>

    <!-- Cancel modal -->
    <app-overlay
      v-if="resource.authorizations.cancel"
      :show="cancelConfirmModalDisplayed"
      @clicked="handleCancelCancel"
    >
      <app-modal @closed="handleCancelCancel">
        <app-confirm
          @cancelled="handleCancelCancel"
          @confirmed="handleCancelConfirm"
        >
          <p>
            {{ t('ttmt.support_requests.cancel.confirm', { support_request_title: resource.attributes.title }) }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import useLocale from '@shared/hooks/locale'
import useDatetime from '@shared/hooks/datetime'
import useSupportRequest from '@shared/hooks/resources/supportRequest'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import ImagesGallery from '@app/components/resources/image/ImagesGallery.vue'
import SupportMessageCard from '@shared/components/resources/support_message/SupportMessageCard.vue'

const { getAttributeTranslation } = useLocale()
const { formatDateShort } = useDatetime()

const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  cancelCallback: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'canceled',
])

const {
  gallery,
  stateDesign,
  canAddMessage,
  firstMessage,
  userMessages,
  showedMessages,
  cancelConfirmModalDisplayed,
  handleCancel,
  handleCancelConfirm,
  handleCancelCancel,
} = useSupportRequest(props, emits)
</script>
