<template>
  <form-group
    name="support_category_id"
    rules="required"
    type="select"
    :hint="t('ttmt.form.hints.support_request.support_categories_search')"
    :form-control-props="{
      options: handleSupportCategoriesSearch,
      filterable: false,
    }"
  >
    <template #control-vue-multiselect-option="option">
      <strong>
        {{ option.title }}
      </strong>
    </template>
  </form-group>

  <form-group
    name="title"
    type="string"
    rules="required"
    :label="t('ttmt.form.labels.support_request.title')"
  />

  <form-group
    name="content"
    type="textarea"
    rules="required"
    :label="t('ttmt.form.labels.support_message.content')"
    :form-control-props="{
      rows: 5,
      limitChars: 600,
    }"
  />
</template>

<script setup>
import { ref } from 'vue'
import { uniqBy } from 'lodash'
import { useI18n } from 'vue-i18n'

import { fetchSupportCategories } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import FormGroup from '@shared/components/form/FormGroup.vue'

const { getAttributeTranslation } = useLocale()
const { t } = useI18n()

// ---------- CATEGORIES ----------

const initialSupportCategorySearch = ref(true)

// Return support categories options used in select control
function formatSupportCategoriesOptions(categories) {
  return categories.map((categoryResource) => ({
    label: getAttributeTranslation(categoryResource.attributes.title),
    title: getAttributeTranslation(categoryResource.attributes.title),
    value: categoryResource.id,
  }))
}

function handleSupportCategoriesSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialSupportCategorySearch.value) {
      initialSupportCategorySearch.value = false
    }

    const params = {
      'search': searchKeywords,
      'app': true,
    }

    fetchSupportCategories(params)
      .then((e) => {
        // Get the new options from the response
        options = uniqBy(options.concat(formatSupportCategoriesOptions(e.data.data)), 'value')
      })
      .finally(() => {
        resolve(options)
      })
  })
}
</script>
