<template>
  <div class="flex justify-between items-center">
    <button
      type="button"
      class="text-sm app-link flex items-center"
      @click="toggleFiltersDisplay"
    >
      <app-icon
        library="coolicon"
        name="slider_02"
        class="mr-2"
        size="text-lg"
      />

      {{ t('ttmt.common.actions.filters') }}

      <span class="bg-theme-500 px-1 pt-0.5 rounded-full text-white text-xs ml-2 w-5 h-5">
        {{ store.getters['filters/getFiltersCount']('supportRequests') }}
      </span>
    </button>

    <transition
      appear
      name="bottom-bar"
    >
      <aside
        v-show="showFilters"
        class="z-40 fixed bottom-0 left-0 w-screen h-screen-3/4 shadow bg-body p-4 overflow-y-scroll"
      >
        <div class="flex flex-col justify-between">
          <div class="flex justify-between items-center mb-4">
            <app-sub-heading>
              {{ t('ttmt.common.actions.filters') }}
            </app-sub-heading>

            <app-button
              feature="close"
              color="theme"
              emphasis="low"
              padding="py-1"
              :hide-text="true"
              :hide-icon="false"
              @click="handleClose"
            />
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <form-group
            name="state"
            type="select"
            margins="mb-2"
            :form-control-props="{
              options: stateOptions
            }"
            @changed="(value) => handleFilterChange('state', value)"
          />

          <div class="flex items-center justify-center">
            <app-button
              feature="reset"
              color="red"
              emphasis="low"
              class="mr-6"
              @click="handleResetFilters"
            />
          </div>
        </div>
      </aside>
    </transition>

    <app-overlay
      :show="showFilters"
      z-index="20"
      @clicked="handleClose"
    />
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { capitalize } from 'lodash'

import useFilters from '@shared/hooks/filters'
import AppSubHeading from '@app/components/ui/AppSubHeading.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const emits = defineEmits([
  'filters-applied',
])

const store = useStore()
const { t } = useI18n()
const showFilters = ref(false)
const form = inject('supportRequestsIndexFiltersForm')

function toggleFiltersDisplay() {
  showFilters.value = !showFilters.value
}

// ---------- STATES ----------

const stateOptions = [
  { value: 'pending-support', label: t('ttmt.support_requests.states.pending-support') },
  { value: 'pending-user', label: t('ttmt.support_requests.states.pending-user') },
  { value: 'canceled', label: t('ttmt.support_requests.states.canceled') },
  { value: 'archived', label: t('ttmt.support_requests.states.archived') },
  { value: 'resolved', label: t('ttmt.support_requests.states.resolved') },
  { value: 'all', label: capitalize(t('ttmt.common.all')) },
]

// ---------- FILTERS ----------

const {
  handleResetFilters: handleResetFiltersHook,
  handleFiltersApplied,
  handleFilterChange,
} = useFilters('supportRequests', form.value, { emits })

function handleFiltersUpdated() {
  handleFiltersApplied()
  toggleFiltersDisplay()
}

function handleClose() {
  handleFiltersUpdated()
}

function handleResetFilters() {
  handleResetFiltersHook()
  toggleFiltersDisplay()
}
</script>
