<template>
  <div class="flex flex-col whitespace-nowrap">
    <!-- Details -->
    <div class="p-3">
      <router-link
        :to="{ name: 'show-support-requests', params: { id: resource.id } }"
        class="block truncate text-base text-left flex items-center"
      >
        <div class="w-full">
          <div
            class="flex items-center mb-2"
          >
            <!-- Title -->
            <span class="font-bold text-base truncate">
              {{ resource.attributes.title }}
            </span>
          </div>

          <!-- Category -->
          <div class="flex text-sm mb-1 items-center">
            <app-icon
              class="mr-1"
              library="coolicon"
              name="tag-outline"
              :size="`text-base`"
            />
            <span>
              {{ getAttributeTranslation(resource.relationships.category.attributes.title) }}
            </span>
          </div>
          <!-- Created at -->
          <div class="flex text-sm items-center justify-between">
            <div class="flex items-center">
              <app-icon
                class="mr-1"
                library="coolicon"
                name="clock"
                :size="`text-base`"
              />
              <span>
                {{ formatDateShort(resource.attributes.created_at) }}
              </span>
            </div>
            <!-- State -->
            <app-status-badge
              :text="t(`ttmt.support_requests.states.${props.resource.attributes.state}`)"
              :design="stateDesign(props.resource.attributes.state)"
            />
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import useSupportRequest from '@shared/hooks/resources/supportRequest'
import useDatetime from '@shared/hooks/datetime'

import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const { t } = useI18n()

const { formatDateShort } = useDatetime()
const { stateDesign } = useSupportRequest()
const { getAttributeTranslation } = useLocale()

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

</script>
