<template>
  <support-request-form
    v-if="!store.state.spinner.active"
    :resource="supportRequest"
    :additional-errors="supportRequestFormErrors"
    :submitting="supportRequestFormSubmitting"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { storeSupportRequest } from '@shared/http/api'
import SupportRequestForm from '@app/components/resources/support_request/SupportRequestForm.vue'

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const supportRequestFormSubmitting = ref(false)
const supportRequestFormErrors = ref({})
const supportRequest = ref({})

function handleSubmit(attributes) {
  if (!supportRequestFormSubmitting.value) {
    supportRequestFormSubmitting.value = true

    const params = {
      data: {
        type: 'support_requests',
        attributes: {
          ...attributes,
          source: 'app',
        },
      },
    }

    storeSupportRequest(params)
      .then((response) => {
        router.push({
          name: 'show-support-requests',
          params: { id: response.data.data?.id },
        })

        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.support_requests.store.success.title'),
            type: 'success',
          },
        )
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          supportRequestFormErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        supportRequestFormSubmitting.value = false
      })
  }
}
</script>
