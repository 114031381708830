<template>
  <support-request-show
    v-if="supportRequest && !loading"
    :resource="supportRequest"
    :cancel-callback="handleCancel"
  />

  <support-request-show-empty
    v-else-if="loading"
  />
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchSupportRequest as fetchApiSupportRequest,
  cancelSupportRequest,
} from '@shared/http/api'
import SupportRequestShow from '@app/components/resources/support_request/SupportRequestShow.vue'
import SupportRequestShowEmpty from '@app/components/resources/support_request/SupportRequestShowEmpty.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const { t } = useI18n()

const supportRequest = ref(null)
const loading = ref(false)

function fetchSupportRequest() {
  return new Promise((resolve) => {
    const { id } = route.params
    loading.value = true

    fetchApiSupportRequest(id)
      .then((response) => {
        supportRequest.value = response.data.data
      })
      .finally(() => {
        loading.value = false
        resolve()
      })
  })
}

function handleCancel() {
  cancelSupportRequest(supportRequest.value.id)
    .then(() => {
      router.push({
        name: 'index-support-requests',
      })

      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('ttmt.support_requests.cancel.success'),
          type: 'success',
        },
      )
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchSupportRequest()
})
</script>
